/* Boutton */

.button {
  &.video {
    font-size: 1rem;
    color: #ff6A4B;
    background: transparent;
    border: 3px solid #ff6A4B;
    border-radius: 5px;
  }
}

/* Scrolling icon */

.arrows path {
  stroke: #ff6A4B;
  fill: transparent;
  stroke-width: 3px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}

/*---------------------- HOME ------------------------*/

#home {
  background:rgba(24, 36, 155, 0.03);
  margin-top: 3rem;
  a {color:inherit!important;}
  nav {
    margin-bottom: 2rem;
    #logo_animation{
      svg {width: 200px!important;}
    }
    .by{color: #18249B;font-size: .9rem; margin-right: 5px;}
  }
  header{
   
    .visuel {
      background-repeat:no-repeat!important;
      background-position: center!important;
      border-radius:10px;
      height: 350px;
      video {
        max-width: 100%;
      }

    }
    .text {
      h2{
       font-size: 2.2rem;
      font-family: $title-font-family;
      font-weight: bold;
      line-height: 1.2;
      margin-top: .5rem;
    }
      p{
      color:#5d6066;
      }
    }
  }
  main{
    margin-top: 5rem;
    div, span, h2, p {
      margin-bottom: 1rem;
    }
    .cover{
      height: 200px;
      background-repeat:no-repeat!important;
      background-position: center!important;
      border-radius:10px;
    }
    h2{
      font-size: 1.8rem;
      font-family: $title-font-family;
     font-weight: bold;
    }
    span{display: block;}
    p{color:#5d6066;}
  }
}


/*---------------------- PAGES TUTOS ------------------------*/

/***************************
Header
***************************/

header {

#videoDiv {
  width: 100%;
  height: 100vh;
  margin: auto;
  display: block;
  position: relative;
  .filtre {
  background : rgba(24,36,155,.8);
  position:absolute;
  width: 100%;
  height: 100%;
  }
  #video1 { width:100%; height:100vh; }
  #videoMessage { 
  position: absolute; top: 0; left: 0;
display: flex;
flex-direction: column; 
justify-content: center;
align-items: center; 
width: 100%;
height: 100%;
    h1, h2 {font-family:$title-font-family;color: #fff;}
    h1{
    font-size: 3.8rem;
    font-weight: bold;
    }
    h2{
    font-size: 1.5rem;
    font-weight: 500;
    position:relative;
    }
  }
}

.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: -80px;
}

.arrows path {
  stroke: #ff6A4B;
  fill: transparent;
  stroke-width: 3px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}
}

/*********************
MAIN
*********************/

/* Scrolling effet */

#cards {
  padding-bottom: calc(var(--numcards) * var(--card-top-offset)); /* Make place at bottom, as items will slide to that position*/
  margin-bottom: var(--card-margin); /* Don't include the --card-margin in padding, as that will affect the scroll-timeline*/
}

@for $i from 1 through 6 {
    #card_#{$i} {
        --index:$i;
    }
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--card-top-offset));
  border: none !important;
  margin-bottom: 0 !important;
}

@supports (animation-timeline: works) {

  @scroll-timeline cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets:
      /* Start when the start edge touches the top of the scrollport */
      selector(#cards) start 1,
      /* End when the start edge touches the start of the scrollport */
      selector(#cards) start 0;
    start: selector(#cards) start 1; /* Start when the start edge touches the top of the scrollport */
    end: selector(#cards) start 0; /* End when the start edge touches the start of the scrollport */
    time-range: 4s;
  }

  .card {
    --index0: calc(var(--index) - 1); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(var(--reverse-index) - 1); /* 0-based reverse index */
  }
  
  .card__content {
    transform-origin: 50% 0%;
    will-change: transform;

    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);

    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform:
        scale(calc(
          1.1
          -
          calc(0.1 * var(--reverse-index))
        ));
    }
  }
}

/** DEBUG **/

#debug {
  position: fixed;
  top: 1em;
  left: 1em;
}
#debug::after {
  content: " Show Debug";
  margin-left: 1.5em;
  color: white;
  white-space: nowrap;
}

#debug:checked ~ main {
  --outline-width: 1px;
}

/* Blocs d'étapes */

main {


  /* Vidéo */
  
  .video-stick {
    margin: 2rem 0;
  .is-at-top{
    iframe {width: 100%;height: 350px;}
  }
  .is-at-top{
  .close-button {display: none;}
  }

  .is-stuck{
    right:15px;
  .close-button {display:block;}
  }
}

  .close-vid {
    border: 1px solid;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 1.2em;
    line-height: 0;
    position: absolute;
    right: 0;
    top: -40px;
  }

.etape{
   &.left{.description {
    padding-right: 2rem;
     @include breakpoint(small only) { padding-right: 0;}
    }
  }
   &.right{.description {
    padding-left: 2rem;
    @include breakpoint(small only) { padding-left: 0;}
    }
  }
   .description{
    .indicatif{
      position:relative;
      span {
        font-size: 8rem;
        font-family: "whitneysemibold", Helvetica, Roboto, Arial, sans-serif;
        color: #eeeff0;
        position: absolute;
        top: -130px;
        z-index: -1;
      }
    }
    h2 {
      font-family: $title-font-family;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.3;
    }
    p{
      font-family: $body-font-family;
      font-size:1.1rem;
      font-weight: 500;
    font-style: normal;
    font-display: swap;
    color: $dark-gray;
    }
    ul{
      margin: 0;
      padding-bottom:1rem;
      li{
        position: relative;
        list-style: none;
        padding-left:2rem;
        color: #7F7F7F;
        &:before{
          position: absolute;
          content: "";
          top: 12%;
          width: 25px;
          height: 17px;
          background-image: url('../img/puce.svg');
          background-repeat: no-repeat;
          left: 0;
        }
        strong {
          font-family: $title-font-family;
          font-weight: bold;
          color: #575568;
        }
      }
    }
  }

  .capture {
    height:100vh;
    background:rgba(24, 36, 155, .03);
    img{box-shadow:-2px 5px 5px #cbc2C0;}
  }
  &.success{
    height: 100vh;
    h2{
    font-family: $title-font-family;
    font-size: 5rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    @include breakpoint(small only) { font-size:3rem;}
    }
    p {
      font-size: 1.5rem;
      line-height: 1;
      @include breakpoint(small only) { font-size:1rem;}
    }
    h2,p {color: #cbc2C0;}
  }
  #save {
    width: 150px;
    margin: 0 auto;
  }
}
}

/*---------------------- FOOTER ------------------------*/

footer{
  background: #18249B;
  padding: 1.5rem;
  p{
    font-family: $title-font-family;
    font-weight: bold;
    color: #D8EDFF;
    margin: 0;
  }
}
